<template>
    <div class="text-danger">
        <i class="bi bi-exclamation-circle-fill"></i>
        Ce type de réponse n'est pas supporté par cette application.
    </div>
</template>

<script>

export default {

}

</script>